import React from "react";
import { Modal } from "antd";

import BookingForm from "./BookingForm";

function BookingModel({ data, isModalOpen, handleCancel }) {
  return (
    <>
      <Modal
        title={data.title}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <BookingForm data={data} />
      </Modal>
    </>
  );
}

export default BookingModel;
