import { useState } from "react";

const useModal = (initialState = false) => {
    const [isModalOpen, setIsModalOpen] = useState(initialState);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);

    };

    return [isModalOpen, showModal, handleCancel];
};

export default useModal;