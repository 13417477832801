import React from "react";
import Footer from "./Footer";
import NavLg from "./navbar/NavLg";
import { Outlet } from "react-router-dom";
import WhatsApp from "../components/WhatsApp";

function Main({ children }) {
  return (
    <>
      <WhatsApp />
      <NavLg />
      <Outlet />
      <Footer />
    </>
  );
}

export default Main;
