import React from "react";

function Gradient() {
  return (
    <section>
      <div class="bg_gradient py-5">
        <h2 class="heading_light text-center">We are always here for you.</h2>
        <p class="text-center text-white p-2 bg__gradient_para">
          If you have any questions or need further assistance, please do not
          hesitate to contact us
        </p>
        <div class="py-5">
          <a
            href="https://api.whatsapp.com/send?phone=97142235534&text=Hello!%20I%20came%20from%20your%20website%20and%20I%20have%20a%20few%20questions"
            class="cta_button2"
          >
            Contact us now
          </a>
        </div>
      </div>
    </section>
  );
}

export default Gradient;
