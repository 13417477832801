import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { fetchData } from "../api";
import ProductCard from "./ProductCard";
import Loader from "./Loader";

function RelatedProduct({ data }) {
  const [relatedProducts, setRelatedProducts] = useState(null);

  useEffect(() => {
    async function getRelatedProducts() {
      const prodData = await fetchData(
        `/api/products?populate=*&filters[product_type][slug][$eq]=${data.product_type.data.attributes.slug}&filters[subdomain][slug][$eq]=${data.subdomain.data.attributes.slug}&filters[slug][$ne]=${data.slug}`
      );

      setRelatedProducts(prodData.data);
    }
    getRelatedProducts();
  }, [data]);
  if (!relatedProducts) {
    return <Loader />;
  }
  if (relatedProducts.length > 0) {
    return (
      <section className="container">
        <div className="releated_products py-5">
          <div className="releated_products__heading">
            <h2 className="heading_dark">Related Products</h2>
            <Link
              to={`/${data.product_type.data.attributes.slug}`}
              className="see_more"
            >
              See more
              <img src="/img/icons/right-arrow.svg" alt="" />
            </Link>
          </div>
          <div className="related_products_slider py-4">
            <div className="swiper2">
              {/* If we need navigation buttons */}
              <Swiper
                spaceBetween={50}
                loop={true}
                breakpoints={{
                  499: {
                    slidesPerView: 1,
                    spaceBetweenSlides: 20,
                  },
                  991: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  1399: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                }}
              >
                {relatedProducts &&
                  relatedProducts.map((element) => {
                    return (
                      <SwiperSlide key={element.id}>
                        <ProductCard product={element.attributes} />
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default RelatedProduct;
