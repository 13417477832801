import React from "react";
import useHomeData from "../hooks/useHomeData";
import ProductCard from "./ProductCard";
import Loader from "./Loader";
function HeadSection() {
  const { homeData } = useHomeData();
  // If homeData is still null or undefined, show the loader
  if (!homeData) {
    return <Loader />;
  }

  return (
    <section className="container pt-5">
      {homeData.title ? (
        <h3 className="styled_text">
          Unbeatable Deals on {homeData.title}'s Top Attractions
        </h3>
      ) : (
        <h3 className="styled_text">Unbeatable Deals on Top Attractions</h3>
      )}
      <h2 className="heading_dark py-2">Start Your Adventure Now</h2>
    </section>
  );
}
function TabButton({ title, id, active }) {
  return (
    <button
      className={`nav-link ${active ? "active" : ""}`}
      id={`nav-${id}-tab`}
      data-bs-toggle="tab"
      data-bs-target={`#nav-${id}`}
      type="button"
      role="tab"
      aria-controls={`nav-${id}`}
      aria-selected={active}
    >
      {title}
    </button>
  );
}
function TabPan({ id, active, children }) {
  return (
    <div
      className={`tab-pane fade ${active ? "show active" : ""}`}
      id={`nav-${id}`}
      role="tabpanel"
      aria-labelledby={`nav-${id}-tab`}
    >
      {children}
    </div>
  );
}

function FilterTabMain({ catData }) {
  // sorting the product in alphabatical order
  const tabTitle = Object.keys(catData).sort();
  const sortedData = {};
  tabTitle.forEach((key) => {
    sortedData[key] = catData[key];
  });

  return (
    <>
      <HeadSection />
      {/* cards section start */}
      <section className="container">
        <div className="filter_search py-2">
          <div className="filters" id="nav-tab" role="tablist">
            {Object.entries(sortedData).map(([key, value], index) => {
              if (index === 0) {
                return <TabButton title={value.title} id={key} active={true} />;
              }
              return <TabButton title={value.title} id={key} />;
            })}
          </div>
        </div>
        <div className="tab-content py-4" id="nav-tabContent">
          {Object.entries(sortedData).map(([key, value], index) => {
            if (index === 0) {
              return (
                <TabPan id={key} active={true}>
                  <div className="products__container">
                    {value.products.map((product) => {
                      if (product.publishedAt) {
                        return (
                          <ProductCard product={product} key={product.id} />
                        );
                      }
                      return null;
                    })}
                  </div>
                </TabPan>
              );
            }
            return (
              <TabPan id={key}>
                <div className="products__container">
                  {value.products.map((product) => {
                    if (product.publishedAt) {
                      return <ProductCard product={product} />;
                    }
                    return null;
                  })}
                </div>
              </TabPan>
            );
          })}
        </div>
      </section>
    </>
  );
}

export default FilterTabMain;
