import React, { useState, useEffect } from "react";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";
import { Helmet } from "react-helmet";
import Gallery from "./Gallery";

import BookingCard from "./BookingCard";
import BookingModel from "./BookingModel";

function DetailContent({ data }) {
  const [seoData, setSeoData] = useState(data);
  const [ogImage, setOgImage] = useState();
  useEffect(() => {
    if (data.seo_details) {
      setSeoData(data.seo_details);
    }

    setOgImage(
      process.env.REACT_APP_API_URL + data.banner_image.data.attributes.url
    );
  }, [data]);

  return (
    <section className="container">
      <Helmet>
        <title>{seoData.title_tag ? seoData.title : data.slug}</title>

        {seoData.meta_description && (
          <meta name="description" content={seoData.meta_description} />
        )}
        {seoData.meta_keywords && (
          <meta name="keywords" content={seoData.meta_keywords} />
        )}
        {seoData.canonical_tag && (
          <link rel="canonical" href={seoData.canonical_tag} />
        )}
        {seoData.meta_robots_tag && (
          <meta name="robots" content={seoData.meta_robots_tag} />
        )}

        {seoData.og_title && (
          <meta property="og:title" content={seoData.og_title} />
        )}
        {seoData.og_description && (
          <meta property="og:description" content={seoData.og_description} />
        )}

        <meta property="og:image" content={ogImage} />
      </Helmet>
      <div className="row">
        <div className="col-12 col-lg-8">
          <div className="filter_search py-2">
            <div className="filters" id="nav-tab" role="tablist">
              {data.overview && (
                <button
                  className="nav-link active"
                  id="nav-overview-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-overview"
                  type="button"
                  role="tab"
                  aria-controls="nav-overview"
                  aria-selected="true"
                >
                  overview
                </button>
              )}
              {data.inclusion && (
                <button
                  className="nav-link"
                  id="nav-inclusion-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-inclusion"
                  type="button"
                  role="tab"
                  aria-controls="nav-inclusion"
                  aria-selected="false"
                >
                  Inclusions
                </button>
              )}
              {data.age_policy && (
                <button
                  className="nav-link"
                  id="nav-age_policy-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-age_policy"
                  type="button"
                  role="tab"
                  aria-controls="nav-age_policy"
                  aria-selected="false"
                >
                  Age policy
                </button>
              )}
              {data.timings && (
                <button
                  className="nav-link"
                  id="nav-timings-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-timings"
                  type="button"
                  role="tab"
                  aria-controls="nav-timings"
                  aria-selected="false"
                >
                  timings
                </button>
              )}
              {data.cancel_policy && (
                <button
                  className="nav-link"
                  id="nav-cancel_policy-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-cancel_policy"
                  type="button"
                  role="tab"
                  aria-controls="nav-cancel_policy"
                  aria-selected="false"
                >
                  cancel policy
                </button>
              )}
            </div>
          </div>
          <div className="tab-content py-4" id="nav-tabContent">
            {data.overview && (
              <div
                className="tab-pane fade show active"
                id="nav-overview"
                role="tabpanel"
                aria-labelledby="nav-overview-tab"
              >
                <BlocksRenderer content={data.overview} />
              </div>
            )}
            {data.inclusion && (
              <div
                className="tab-pane fade"
                id="nav-inclusion"
                role="tabpanel"
                aria-labelledby="nav-inclusion-tab"
              >
                <BlocksRenderer content={data.inclusion} />
              </div>
            )}
            {data.age_policy && (
              <div
                className="tab-pane fade"
                id="nav-age_policy"
                role="tabpanel"
                aria-labelledby="nav-age_policy-tab"
              >
                <BlocksRenderer content={data.age_policy} />
              </div>
            )}
            {data.timings && (
              <div
                className="tab-pane fade"
                id="nav-timings"
                role="tabpanel"
                aria-labelledby="nav-timings-tab"
              >
                <BlocksRenderer content={data.timings} />
              </div>
            )}
            {data.cancel_policy && (
              <div
                className="tab-pane fade"
                id="nav-cancel_policy"
                role="tabpanel"
                aria-labelledby="nav-cancel_policy-tab"
              >
                <BlocksRenderer content={data.cancel_policy} />
              </div>
            )}
          </div>
          {/* gallery start */}
          {data.gallery_images && (
            <Gallery imgData={data.gallery_images.data} />
          )}
          {/* gallery end */}
        </div>
        <div className="col-12 col-lg-4">
          {/* Temp content */}
          <div className="sticky_card">
            <BookingCard data={data} />
            <BookingModel data={data} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default DetailContent;
