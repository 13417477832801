import React from "react";
import PropTypes from "prop-types";

const WHATSAPP_BASE_URL = "https://api.whatsapp.com/send";
const DEFAULT_PHONE = "971503431800";
const DEFAULT_MESSAGE =
  "Hello, I came from your website, and I would like to book a ticket for (enter the name of the attraction here)";
const DEFAULT_TEXT = "Message us";

const WhatsApp = ({
  phone = DEFAULT_PHONE,
  message = DEFAULT_MESSAGE,
  children = DEFAULT_TEXT,
}) => {
  const handleClick = () => {
    const url = `${WHATSAPP_BASE_URL}?phone=${phone}&text=${encodeURIComponent(
      message
    )}`;
    window.open(url, "_blank");
  };

  return (
    <div
      className="whatsapp_chat"
      onClick={handleClick}
      role="button"
      tabIndex={0}
    >
      <img src="/img/icons/whatsapp_chat.svg" alt="WhatsApp Chat Icon" />
      <span className="d-none d-md-flex">{children}</span>
    </div>
  );
};

WhatsApp.propTypes = {
  phone: PropTypes.string,
  message: PropTypes.string,
  children: PropTypes.node,
};

export default WhatsApp;
