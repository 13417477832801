import LeftBox from "./LeftBox";
import RightBox from "./RightBox";

function Footer() {
  return (
    <footer>
      <div className="footer">
        <div className="footer__left" />
        <div className="footer__right" />
        <div className="container">
          <div className="content_box py-5">
            <LeftBox />
            <RightBox />
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
