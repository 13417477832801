import axios from 'axios';

const params = {
    headers: {
        Authorization: "bearer " + process.env.REACT_APP_API_KEY,

    }
}

export const fetchData = async (url) => {
    try {
        const { data } = await axios.get(process.env.REACT_APP_API_URL + url, params);
        return data;

    } catch (error) {
        console.log(error);
        return error;

    }
}

export const postData = async (url, postData) => {
    try {
        const { data } = await axios.post(process.env.REACT_APP_API_URL + url, postData, params);
        return data;

    } catch (error) {
        console.log(error);
        return error;

    }
}

export const putData = async (url, postData) => {
    try {
        const { data } = await axios.put(process.env.REACT_APP_API_URL + url, postData, params);
        return data;

    } catch (error) {
        console.log(error);
        return error;

    }
}