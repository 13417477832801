import React from "react";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import FilterTabMain from "../components/FilterTabMain";
import Testimonial from "../components/Testimonial";
import Gradient from "../components/Gradient";
import Loader from "../components/Loader";
import useHomedata from "../hooks/useHomeData";
import useCategorizedData from "../hooks/useCatagoriesdData";

function Home() {
  const { homeData } = useHomedata();
  const { categorizedData } = useCategorizedData();

  if (!categorizedData || !homeData) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>{`${homeData.title} | OutMazed® Tourism`}</title>
      </Helmet>
      <Header
        heading={homeData.title}
        subheading={homeData.subTitle}
        imgUrl={homeData.image}
      />
      <FilterTabMain catData={categorizedData} />
      <Testimonial />
      <Gradient />
    </>
  );
}

export default Home;
