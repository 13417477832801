import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
const override = {
  display: "block",
  margin: "200px auto",
};
function Loader() {
  return <ClipLoader color="#0061ab" cssOverride={override} />;
}

export default Loader;
