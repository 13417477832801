import React from "react";

function Header({
  imgUrl = "/img/banner/hero_banner.jpg",
  heading,
  subheading,
  location,
}) {
  return (
    <header
      className="hero-header"
      style={{ backgroundImage: `url(${imgUrl})` }}
    >
      <div className="hero-header__content">
        {heading && <h1 className="main_heading">{heading}</h1>}
        {subheading && <h2 className="main_subheading">{subheading}</h2>}
        {location && (
          <div class="location_tag">
            <span>{location}</span>
          </div>
        )}
      </div>
    </header>
  );
}

export default Header;
