import { useEffect, useState } from "react";
import { fetchData } from "../api";

function useHomeData() {
    const [homeData, setHomeData] = useState(null);
    const [error, setError] = useState(null);

    const subdomain = process.env.REACT_APP_SUBDOMAIN_ID;
    const backend = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchHomeData = async () => {
            try {
                const response = await fetchData(
                    `/api/subdomains/${subdomain}/?populate[image][populate][1]=image`
                );
                const data = response.data.attributes;
                setHomeData(transformHomeData(data));
            } catch (error) {
                console.error("Error fetching home data:", error);
                setError(error);
            }
        };

        fetchHomeData();
    }, [subdomain, backend]); // Include dependencies if they might change

    function transformHomeData(data) {
        return {
            title: data.title,
            subTitle: data.subtitle || "",
            endpoint: data.endpoint_url || "",
            image: data.image ? `${backend}${data.image.data.attributes.url}` : "",
            slug: data.slug,
        };
    }

    return { homeData, error };
}

export default useHomeData;