import React from "react";
import Header from "../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../components/Loader";
import ProductCard from "../components/ProductCard";
import useFetchProductsByType from "../hooks/useFetchProductsByType";

function ProductType() {
  const { productType } = useParams();
  const navigate = useNavigate();
  const { products, homeData } = useFetchProductsByType(productType);

  if (!products) {
    return <Loader />;
  }

  if (products.length < 1) {
    navigate("/not-found");
  }

  return (
    <>
      <Header heading={productType} imgUrl={homeData.image} />
      <div className="container">
        <div className="products__container py-5">
          {products.map((pro) => {
            return <ProductCard product={pro.attributes} key={pro.id} />;
          })}
        </div>
      </div>
    </>
  );
}

export default ProductType;
