import React, { useState } from "react";
import { Popover, Modal } from "antd";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
function DetailTitle({ detailTitle, location, productData }) {
  const shareUrl = window.location.href;
  const title = "Check this out!";
  const content = (
    <div>
      <FacebookShareButton
        url={shareUrl}
        quote={title}
        style={{
          margin: "5px",
        }}
      >
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <TwitterShareButton
        url={shareUrl}
        title={title}
        style={{
          margin: "5px",
        }}
      >
        <TwitterIcon size={32} round />
      </TwitterShareButton>
      <LinkedinShareButton
        url={shareUrl}
        title={title}
        style={{
          margin: "5px",
        }}
      >
        <LinkedinIcon size={32} round />
      </LinkedinShareButton>
    </div>
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <section className="py-3">
        <div className="container">
          <div className="detail_page_head">
            <div>
              <h2 className="heading_dark fs-3">{detailTitle}</h2>
              <ul className="detail_small_icons">
                <li>
                  <img src="/img/icons/star.svg" alt="" srcSet="" />
                  <span>4.8 - 100 reviews</span>
                </li>
                {location && (
                  <li>
                    <img src="/img/icons/small_location.svg" alt="" srcSet="" />
                    <span>{location}</span>
                  </li>
                )}
                <Popover content={content} title="Share">
                  <li>
                    <img src="/img/icons/small_share.svg" alt="" srcSet="" />
                    <span>Share</span>
                  </li>
                </Popover>
                <li>
                  <img src="/img/icons/small_save.svg" alt="" srcSet="" />
                  <span>Save</span>
                </li>
                <li onClick={showModal}>
                  <img src="/img/icons/small_info.svg" alt="" srcSet="" />
                  <span>How to book</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        centered
      >
        <div class="step">
          <h6>Step 1:</h6>
          <p>Navigate to the product page and find the "Book Now" form.</p>
        </div>
        <div class="step">
          <h6>Step 2:</h6>
          <p>Choose the number of adults and children.</p>
          <p>Select your desired date.</p>
          <p>The total price for your selection will be displayed.</p>
        </div>
        <div class="step">
          <h6>Step 3:</h6>
          <p>Review your selections.</p>
          <p>Click "Book now" to proceed to the payment page.</p>
          <p>Enter your payment details and complete the purchase.</p>
        </div>
      </Modal>
    </>
  );
}

export default DetailTitle;
