import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Thumbs } from "swiper/modules";

export default function Gallery({ imgData }) {
  const [allImage, setAllImage] = useState(null);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  useEffect(() => {
    if (!imgData || imgData.length === 0) {
      return;
    }

    const images = imgData.map(
      (img) => process.env.REACT_APP_API_URL + img.attributes.url
    );

    setAllImage(images);
  }, [imgData]);

  if (!imgData || imgData.length === 0) {
    return null;
  }

  return (
    <div className="product_detail_gallery">
      <h2 className="heading_dark py-2">Gallery</h2>
      <div className="product_detail_gallery__wrapper">
        <div className="product_detail_gallery__main_image">
          <Swiper
            modules={[Navigation, Thumbs, Autoplay]}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            thumbs={{
              swiper:
                thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
            }}
          >
            {allImage &&
              allImage.map((img, index) => (
                <SwiperSlide key={index}>
                  <img src={img} alt="" />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
        <div className="product_detail_gallery__extra_images">
          <Swiper
            modules={[Thumbs, Navigation]}
            navigation={true}
            spaceBetween={10}
            slidesPerView={3}
            loop={true}
            onSwiper={setThumbsSwiper}
            watchSlidesProgress={true}
          >
            {allImage &&
              allImage.map((img, index) => (
                <SwiperSlide key={index}>
                  <img src={img} alt="" />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
}
