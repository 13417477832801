import { useState, useEffect } from "react";
import { postData } from "../api";

const useBookingForm = (data, numberOptions) => {
    const [visitors, setVisitors] = useState({
        adults: numberOptions.length > 0 ? 1 : 0,
        children: 0,
    });
    const [selectedDate, setSelectedDate] = useState(null);
    const [errors, setErrors] = useState({});

    const ticketTypesAvailable = data.ticket_type && data.ticket_type.length > 0;
    const transportTypesAvailable =
        data.transport_type && data.transport_type.length > 0;
    const timeAvailable = data.time && data.time.length > 0;

    const mappedTicketTypes = ticketTypesAvailable
        ? data.ticket_type.map((ticket) => ({
            id: ticket.id,
            label: ticket.label,
            value: ticket.label,
            adultPrice: ticket.adult_price,
            childPrice: ticket.child_price,
        }))
        : [];
    const mappedTransportTypes = transportTypesAvailable
        ? data.transport_type.map((transport) => ({
            id: transport.id,
            label: transport.label,
            value: transport.label,
            adultPrice: transport.adult_price,
            childPrice: transport.child_price,
        }))
        : [];

    const [selectedTransport, setSelectedTransport] = useState(
        transportTypesAvailable
            ? mappedTransportTypes[0]
            : { adultPrice: 0, childPrice: 0 }
    );
    const [selectedTicketType, setSelectedTicketType] = useState(
        ticketTypesAvailable
            ? mappedTicketTypes[0]
            : { adultPrice: data.adult_price, childPrice: data.child_price }
    );
    const [adultPrice, setAdultPrice] = useState(selectedTicketType.adultPrice);
    const [childPrice, setChildPrice] = useState(selectedTicketType.childPrice);
    const [total, setTotal] = useState(
        visitors.adults * adultPrice + visitors.children * childPrice
    );
    const [selectedTime, setSelectedTime] = useState('');
    const [timeLabel, setTimeLabel] = useState(
        timeAvailable ? data.time : []
    );



    useEffect(() => {
        setTotal(
            visitors.adults * adultPrice +
            visitors.children * childPrice +
            (selectedTransport
                ? visitors.adults * selectedTransport.adultPrice +
                visitors.children * selectedTransport.childPrice
                : 0)
        );
    }, [visitors, adultPrice, childPrice, selectedTransport]);

    useEffect(() => {
        if (selectedTicketType) {
            setAdultPrice(selectedTicketType.adultPrice);
            setChildPrice(selectedTicketType.childPrice);
        }
    }, [selectedTicketType]);
    const validateForm = () => {
        const newErrors = {};
        if (!visitors.adults) newErrors.adults = "Required adults.";
        if (!selectedDate) newErrors.date = "Please select a date.";
        if (timeAvailable) {
            if (!selectedTime) newErrors.time = "Please select a time.";
        }

        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = validateForm();
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
            const bookingData = {
                slug: data.slug,
                adults: visitors.adults,
                children: visitors.children,
                ticket: selectedTicketType,
                transport: selectedTransport,
                date: selectedDate,
                time: selectedTime

            }

            const res = await postData("/api/orders", bookingData).then((res) => {


                window.location.href = res.url;
            });

        }
    };


    return {
        visitors,
        setVisitors,
        selectedDate,
        setSelectedDate,
        selectedTime,
        setSelectedTime,
        errors,
        handleSubmit,
        ticketTypesAvailable,
        transportTypesAvailable,
        mappedTicketTypes,
        mappedTransportTypes,
        selectedTransport,
        setSelectedTransport,
        selectedTicketType,
        setSelectedTicketType,
        adultPrice,
        childPrice,
        total,
        timeAvailable,
        timeLabel,
    };
};

export default useBookingForm;