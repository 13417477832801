import { useState, useEffect, useCallback } from "react";
import { fetchData } from "../api";
import useHomeData from "./useHomeData";

function useFetchProductsByType(productType) {
    const [products, setProducts] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { homeData } = useHomeData();

    const fetchProducts = useCallback(async () => {
        if (!homeData) return;

        try {
            const productData = await fetchData(
                `/api/products?populate=*&filters[product_type][slug][$eq]=${productType}&filters[subdomain][slug][$eq]=${homeData.slug}`
            );
            setProducts(productData.data);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    }, [productType, homeData]);

    useEffect(() => {
        fetchProducts();
    }, [fetchProducts]);

    return { products, homeData, loading, error };
}

export default useFetchProductsByType;