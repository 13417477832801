import React from "react";

import BookingForm from "./BookingForm";

function BookingCard({ data }) {
  return (
    <div className="booking_card shadow">
      <h2 className="text-center p-3 fs-4 ">Reserve Your Spot Now!</h2>

      <BookingForm data={data} />
    </div>
  );
}

export default BookingCard;
