import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import DetailTitle from "../components/DetailTitle";
import DetailContent from "../components/DetailContent";
import RelatedProduct from "../components/RelatedProduct";

import { useParams } from "react-router-dom";
import { fetchData } from "../api";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";

function Detail() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { slug, productType } = useParams();

  useEffect(() => {
    const fetchDataFromApi = async () => {
      try {
        const productData = await fetchData(
          `/api/products?filters[slug][$eq]=${slug}&populate=*`
        );
        if (!productData.data[0]) {
          throw new Error("Product not found");
        }
        setData(productData.data[0].attributes);
      } catch (error) {
        console.error("Error fetching data:", error);
        setData(null);
      } finally {
        setLoading(false);
      }
    };

    fetchDataFromApi();
  }, [slug, productType]);

  if (loading) {
    return <Loader />;
  }

  if (!data) {
    // Redirect to another page if data is not found
    navigate("/not-found");
    return null;
  }

  return (
    <>
      <Header
        imgUrl={
          process.env.REACT_APP_API_URL + data.banner_image.data.attributes.url
        }
        heading={data.title}
        location={data.location}
      />
      <DetailTitle
        detailTitle={data.booking_phrase}
        location={data.location}
        productData={data}
      />
      <DetailContent data={data} />
      <RelatedProduct data={data} />
    </>
  );
}

export default Detail;
