export function generateNumbers(num) {
    let numberOptions = [];
    for (let i = 0; i < num + 1; i++) {
        numberOptions.push({ value: i, label: i });
    }
    return numberOptions;
}

export function disabledDate(current) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return current && current.valueOf() < today.getTime();
}