import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Detail from "./pages/Detail";

import NotFound from "./pages/NotFound";
import Main from "./layouts/Main";
import ScrollToTopOnRouteChange from "./utils/ScrollToTopOnRouteChange";
import Success from "./pages/Success";
import Canceled from "./pages/Canceled";
import ProductType from "./pages/ProductType";
import ThankYou from "./pages/ThankYou";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTopOnRouteChange />
      <Routes>
        <Route path="/" element={<Main />}>
          <Route index element={<Home />} />
          <Route path="/:productType" element={<ProductType />} />
          <Route path="/:productType/:slug?" element={<Detail />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/not-found" element={<NotFound />} />
          <Route path="/success" element={<Success />} />
          <Route path="/canceled" element={<Canceled />} />
          <Route path="/thank-you" element={<ThankYou />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
