import React from "react";
import Header from "../components/Header";

function ThankYou() {
  return (
    <>
      <Header imgUrl="/img/banner/thankyou.jpg" />;
      <div className="container">
        <div className="text-center content_container mx-auto">
          <h2 className="main_heading text-omt-orange">THANK YOU!</h2>
          <h3 className="subheading_with_icon">
            <img src="/img/icons/green_tic.svg" alt="" /> Your request has been
            submitted
          </h3>
          <p className="para">
            We will contact you soon to discuss your request and help you find
            the perfect option. In the meantime, please feel free to browse our
            website or contact us if you have any questions.
          </p>
        </div>
      </div>
    </>
  );
}

export default ThankYou;
