import React from "react";

function LeftBox() {
  return (
    <div className="content_box__left ">
      <div className="footer_logo_container">
        <div className="logo_wrapper_footer">
          <img src="/img/outmazed-tourism-logo.svg" alt="" />
        </div>
      </div>
      <div className="follow_us">
        <div className="content py-4">Follow Us</div>
        <ul className="footer_social">
          <li>
            <a
              href="https://www.instagram.com/outmazedtourism/"
              target="_blank"
              aria-label="instagram"
              rel="noopener noreferrer"
            >
              <i className="fa-brands fa-instagram" />
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/OutMazedTourism/"
              target="_blank"
              aria-label="facebook"
              rel="noopener noreferrer"
            >
              <i className="fa-brands fa-facebook-f" />
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/@OutMazedTourism"
              target="_blank"
              aria-label="youtube"
              rel="noopener noreferrer"
            >
              <i className="fa-brands fa-youtube" />
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/outmazedtourism/"
              target="_blank"
              aria-label="linkedin"
              rel="noopener noreferrer"
            >
              <i className="fa-brands fa-linkedin-in" />
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/OutMazedT"
              target="_blank"
              aria-label="twitter"
              rel="noopener noreferrer"
            >
              <i className="fa-brands fa-twitter" />
            </a>
          </li>
          <li>
            <a
              href="https://www.tiktok.com/@outmazedtourism"
              target="_blank"
              aria-label="tiktok"
              rel="noopener noreferrer"
            >
              <i className="fa-brands fa-tiktok" />
            </a>
          </li>
          <li>
            <a
              href="https://wa.me/971503431800 "
              target="_blank"
              aria-label="whatsapp"
              rel="noopener noreferrer"
            >
              <i className="fa-brands fa-whatsapp" />
            </a>
          </li>
        </ul>
      </div>
      <div className="location">
        <div className="content ">Our Location</div>
        <p>
          OutMazed Tourism LLC
          <br />
          Office 2212, 22
          <sup style={{ fontSize: "12px" }}>nd</sup> Floor, SIT Tower, Dubai
          Silicon Oasis, Dubai,
          <br />
          United Arab Emirates
        </p>
        <div className="copyright py-3">
          <p>© OutMazed® Tourism</p>
        </div>
      </div>
    </div>
  );
}

export default LeftBox;
