import React from "react";

import Header from "../components/Header";

function NotFound() {
  return (
    <>
      <Header heading="404 not found" />
    </>
  );
}

export default NotFound;
