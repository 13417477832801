import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import { useLocation } from "react-router-dom";
import { putData } from "../api";

function Canceled() {
  const sessionId = new URLSearchParams(useLocation().search).get("session_id");
  const [paymentUrl, setPaymentUrl] = useState(null);

  useEffect(() => {
    async function getPaymentUrl() {
      if (sessionId) {
        try {
          const res = await putData(`/api/orders/${sessionId}`);
          if (res && res.url) {
            setPaymentUrl(res.url);
          }
        } catch (error) {
          console.error("Error fetching payment URL:", error);
        }
      }
    }
    getPaymentUrl();
  }, [sessionId]);

  function handleClick() {
    if (paymentUrl) {
      window.location.href = paymentUrl;
    }
  }

  return (
    <>
      <Header heading="Canceled" subheading="Your payment got canceled" />
      {paymentUrl && (
        <div className="text-center py-4 px-2">
          <p className="fs-5 lh-base">
            Unfortunately, your payment was not successful. To attempt the
            payment again, please click the button below.
          </p>
          <button className="btn btn-primary btn-lg " onClick={handleClick}>
            Try Again
          </button>
        </div>
      )}
    </>
  );
}

export default Canceled;
