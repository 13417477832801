import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { useLocation } from "react-router-dom";
import { putData } from "../api";

function Success() {
  const [title, setTitle] = useState("");
  const [requestSent, setRequestSent] = useState(false); // Add a state to track if the request has been sent
  const sessionId = new URLSearchParams(useLocation().search).get("session_id");

  useEffect(() => {
    async function sendDataToBackend() {
      if (sessionId && !requestSent) {
        // Check if request has already been sent
        try {
          const res = await putData(`/api/orders/${sessionId}`);
          setTitle(res.title);
          setRequestSent(true); // Set the flag to true after sending the request
        } catch (error) {
          console.error("Error sending data", error);
        }
      }
    }
    sendDataToBackend();
  }, [sessionId, requestSent]); // Add requestSent to the dependency array

  return (
    <>
      <Header heading="Success" subheading="Your Payment was successful!" />
      <div className="container py-2 fs-3 ">
        <h2>Thank you for booking with OutMazed Tourism</h2>
        <p>
          We are pleased to inform you that your payment for the{" "}
          <strong className="fw-bold">{title}</strong> has been successfully
          processed.
        </p>
        <p>
          You will receive another email with your ticket(s) attached and
          additional information about the attraction.
        </p>
      </div>
    </>
  );
}

export default Success;
